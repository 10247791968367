import React from 'react';
import {Button, Col, Input, ModalBody, Row, Table} from "reactstrap";
import {MultiValue, useEntity} from "react-admin-base";
import {ModalEntityEditor} from "react-admin-base-bootstrap";
import {Field} from "../../Components/Fields";
import CurrencyInput from "../../Components/CurrencyInput";

export default function ProductEntity({url, onReload, id}) {
    const entity = useEntity('/product', id, {  });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Product Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <Field title="Name" type="required">
                <Input
                    type="text"
                    value={data.name || ''}
                    onChange={e => setData({ name: e.target.value })}
                />
            </Field>
            <Field title="Internal Note" type="required">
                <Input
                    type="text"
                    value={data.internal_notes || ''}
                    onChange={e => setData({ internal_notes: e.target.value })}
                />
            </Field>
            <Field title="Price" type="required|numeric">
                <CurrencyInput
                    type="text"
                    value={data.price || ''}
                    onChange={e => setData({ price: e.target.value })}
                />
            </Field>
        </ModalBody>
        <Options
            value={data.options}
            onChange={options => setData({ options })}
        />
    </ModalEntityEditor>;
}

function Options({ value, onChange }) {
    return <Table hover striped>
        <thead>
            <tr>
                <th>Option</th>
                <th>Value</th>
            </tr>
        </thead>
        <tbody>
            <MultiValue onChange={data => onChange(Object.fromEntries(data.map(a => [a.key, a.value])))} value={Object.entries(value || []).map(([key,value]) => ({ key, value }))}>
                { (row, onChange, index) => <tr key={index}>
                    <td>
                        <Input
                            type="text"
                            placeholder="Option"
                            value={row?.key || ''}
                            onChange={e => onChange({ key: e.target.value, value: row?.value || '' })}
                        />
                    </td>
                    <td>
                        <Input
                            type="text"
                            placeholder="Value"
                            value={row?.value || ''}
                            onChange={e => onChange({ value: e.target.value, key: row?.key || '' })}
                            disabled={!row}
                        />
                    </td>
                    <td className="min text-center">
                        <Button
                            type="button"
                            color="danger"
                            onClick={e => onChange(null)}
                            disabled={!row}
                        >
                            <i className="fas fa-trash" />
                        </Button>
                    </td>
                </tr> }
            </MultiValue>
        </tbody>
    </Table>;
}