import React from 'react';
import {Menu, MenuGroup, Sidebar} from 'react-admin-base-nazox';
import {useUser} from "./providers/UserProvider";

export default function MenuSidebar() {
    const user = useUser();
    
    return <Sidebar>
        <Menu to="/dashboard" title="Dashboard" icon="fas fa-users" />
        <Menu to="/profile" title="My profile" icon="fas fa-address-card" />
        { user.type === 512 && <MenuGroup title="Admin">
            <Menu to="/language" title="Languages" icon="fas fa-language" />
            <Menu to="/company" title="Companies" icon="fas fa-building" />
            <Menu to="/shop" title="Shops" icon="fas fa-shop" />
            <Menu to="/product" title="Products" icon="fas fa-shirt" />
            <Menu to="/user" title="Users" icon="fas fa-users" />
        </MenuGroup> }
    </Sidebar>;
}
