import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn, RowRenderer} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-nazox';
import CompanyEntity from "./CompanyEntity";

export default function Companies() {
    return <Breadcrumb
        title="Companies"
        data={
            [
                {
                    href: '/company',
                    name: 'Companies'
                }
            ]
        }
    >
        <CRUD url="/company" Component={CompanyEntity}>
            <thead>
                <tr>
                    <IdColumn/>
                    <Column sort="name">Name</Column>
                    <ActionsColumn/>
                </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.name}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/company/" + row.id}
                    />
                </tr>}
            />
        </CRUD>
    </Breadcrumb>;
}
