import React from 'react';
import {Input, ModalBody} from "reactstrap";
import {useEntity} from "react-admin-base";
import {ModalEntityEditor} from "react-admin-base-bootstrap";
import {Field} from "../../Components/Fields";
import {BillingPeriodSelect} from "../../Components/Selects";

export default function CompanyEntity({url, onReload, id}) {
    const entity = useEntity('/company', id, {  });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Company Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <Field title="Name" type="required">
                <Input
                    type="text"
                    value={data.name || ''}
                    onChange={e => setData({ name: e.target.value })}
                />
            </Field>
            <Field title="Start Date" type="required">
                <Input
                    type="date"
                    value={data.start_date || ''}
                    onChange={e => setData({ start_date: e.target.value })}
                />
            </Field>
            <Field title="Billing Period" type="required">
                <BillingPeriodSelect
                    value={data.period}
                    onChange={e => setData({ period: e })}
                />
            </Field>
        </ModalBody>
    </ModalEntityEditor>;
}
