import React from "react";
import {Validator} from "react-admin-base-bootstrap";
import {FormGroup, FormText, Label} from "reactstrap";

export function Field({title, validatorTitle, type, children, desc}: any) {
    const isRequired = (type && type.indexOf("required") !== -1) || false;

    return <FormGroup>
        <Label><b>{title}</b>{isRequired && <sup><i className="fas fa-asterisk ms-1 text-danger field-required"/></sup>}
        </Label>
        <Validator name={validatorTitle || title} type={type}>
            {children[0] || children}
        </Validator>
        {children[1]}
        {desc && <FormText color="muted">
            {desc}
        </FormText>}
    </FormGroup>
}

export function FieldStatic({title, children, desc}: any) {
    return <FormGroup>
        <Label><b>{title}</b></Label>
        {children[0] || children}
        {children[1]}
        {desc && <FormText color="muted">
            {desc}
        </FormText>}
    </FormGroup>
}
