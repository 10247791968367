import './App.scss';

import {App, Authorized, AuthProvider, ForgotPasswordRoute, LoginRoute, NotAuthorized} from 'react-admin-base';
import {BootstrapOptionsProvider} from 'react-admin-base-bootstrap';
import {Login, MainLayout, Reset, ThemeOptionProvider} from 'react-admin-base-nazox';

import MenuSidebar from "./MenuSidebar";
import Router from "./Router";
import MenuHeader from "./MenuHeader";
import Footer from "./Footer";
import {UserProvider} from "./providers/UserProvider";
import UploadConfig from "./UploadConfig";
import {BrowserRouter} from "react-router-dom";
import LanguageProvider from "./providers/LanguageProvider";

function BaseApp() {
    return (
        <BrowserRouter basename={process.env.REACT_APP_PATHBASE || undefined}>
            <App
                id="shopgiftr"
                name="Shopgiftr"
                endpoint={process.env.REACT_APP_ENDPOINT}
            >
                <ThemeOptionProvider noLoginBanner>
                    <BootstrapOptionsProvider noCloseModal onlySaveOnDirty>
                        <AuthProvider
                            tokenEndpoint="/token"
                            client_id="3da4385d-5166-4c1f-9c4b-49afe82be47e"
                            client_secret="123456"
                        >
                            <LanguageProvider>
                                <UploadConfig>
                                    <NotAuthorized>
                                        <LoginRoute>
                                            <Login/>
                                        </LoginRoute>
                                        <ForgotPasswordRoute>
                                            <Reset/>
                                        </ForgotPasswordRoute>
                                    </NotAuthorized>
                                    <Authorized>
                                        <UserProvider>
                                            <MainLayout>
                                                <MenuHeader/>
                                                <MenuSidebar/>
                                                <Router/>
                                                <Footer/>
                                            </MainLayout>
                                        </UserProvider>
                                    </Authorized>
                                </UploadConfig>
                            </LanguageProvider>
                        </AuthProvider>
                    </BootstrapOptionsProvider>
                </ThemeOptionProvider>
            </App>
        </BrowserRouter>
    );
}

export default BaseApp;
