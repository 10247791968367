import React from 'react';
import {Input, ModalBody} from "reactstrap";
import {useEntity} from "react-admin-base";
import {ModalEntityEditor} from "react-admin-base-bootstrap";
import {Field} from "../../Components/Fields";
import {CompanySelect, MapSelect} from "../../Components/Selects";

const integrationTypes = {
    1: {
        name: "ShipStation",
        component({value, onChange}) {
            return <>
                <Field title="Api Key" type="required">
                    <Input
                        type="text"
                        value={value?.username || ''}
                        onChange={a => onChange({
                            ...(value || {}),
                            username: a.currentTarget.value
                        })}
                    />
                </Field>
                <Field title="Api Secret" type="required">
                    <Input
                        type="text"
                        value={value?.apiKey || ''}
                        onChange={a => onChange({
                            ...(value || {}),
                            apiKey: a.currentTarget.value
                        })}
                    />
                </Field>
            </>;
        }
    }
};

export default function ShopEntity({url, onReload, id}) {
    const entity = useEntity('/shop', id, {  });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }
    
    const Component = data.type && integrationTypes[data.type].component;
    
    return <ModalEntityEditor size="lg" title="Shop Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <Field title="Name" type="required">
                <Input
                    type="text"
                    value={data.name || ''}
                    onChange={e => setData({ name: e.target.value })}
                />
            </Field>
            <Field title="Company" type="required">
                <CompanySelect
                    value={data.company}
                    onChange={company => setData({ company })}
                />
            </Field>
            <Field title="Integration Type" type="required">
                <MapSelect
                    staticOptions={Object.entries(integrationTypes)
                            .reduce((a, [key, val]) => ({...a, [key]: val.name}), {})}
                    value={data.type}
                    onChange={type => setData({type})}
                />
            </Field>
            {Component && <Component
                value={data.options}
                onChange={options => setData({options})}
            />}
        </ModalBody>
    </ModalEntityEditor>;
}
