import React from "react";
import {GoToTop} from 'react-admin-base-bootstrap';
import {FooterLayout} from 'react-admin-base-nazox';

export default function Footer() {
    return <FooterLayout>
        <GoToTop/>
    </FooterLayout>;
}


