import React, {useMemo} from 'react';
import {ApiSelect, CreateSelect, Relative} from "react-admin-base-bootstrap";
import CompanyEntity from "../pages/Company/CompanyEntity";
import {useCompanyId} from "../providers/CompanyProvider";

export function CompanySelect(props) {
    return <CreateSelect
        Component={CompanyEntity}
        url="/company"
        {...props}
    />;
}

export function LanguageSelect(props) {
    return <ApiSelect url="/language" {...props}>
        { row => <>
            { row.icon && <Relative><img style={{ width: '16px', height: '16px', marginRight: '5px' }} src={row.icon.access_url} alt={row.name} /></Relative> }
            { row.name }
        </>}
    </ApiSelect>;
}

export function MapSelect(props) {
    const { value, onChange, staticOptions } = props;

    return <ApiSelect
        {...props}
        staticOptions={Object.entries(staticOptions).map(a => ({ id: +a[0], name: a[1] }))}
        value={value && { id: +value, name: staticOptions[value] }}
        onChange={a => onChange(a && a.id)}
    />;
}

export function UserSelect(props) {
    return <ApiSelect
        url="/user"
        {...props}
    />;
}

export function PeriodSelect(props) {
    const companyId = useCompanyId();
    
    return <ApiSelect
        url={"/company/" + companyId + "/period"}
        {...props}
    >
        { row => <>{row.index}. {row.start} - {row.end}</> }
    </ApiSelect>;
}

export function BillingPeriodSelect(props) {
    const options = useMemo(() => ({
        1: 'Weekly',
        2: 'Monthly'
    }), []);
    
    return <MapSelect
        staticOptions={options}
        {...props}
    />;
}
