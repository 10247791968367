import React from 'react';
import {Card, CardBody} from "reactstrap";
import {useEntity} from "react-admin-base";
import {EntityEditor} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-nazox';
import UserInfoInput from '../../Components/UserInfoInput';

export default function ProfileEdit() {
    const entity = useEntity('/user', 'me');
    const [data, setData] = entity;

    return <>
        <Breadcrumb
            title="My Profile"
            data={
                [
                    {
                        href: '/profil',
                        name: 'My Profile'
                    }
                ]
            }
        />
        <EntityEditor entity={entity}>
            <Card>
                <CardBody>
                    <UserInfoInput
                        data={data}
                        setData={setData}
                        required
                    />
                </CardBody>
            </Card>
        </EntityEditor>
    </>;
}
