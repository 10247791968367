import {Input, InputGroup, InputGroupText} from "reactstrap";

export default function CurrencyInput(props) {
    return <InputGroup>
        <InputGroupText>
            $
        </InputGroupText>
        <Input
            type="text"
            {...props}
        />
    </InputGroup>;
}

const format = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export function Money({ value }) {
    return <>
        { format.format(value || 0) }
    </>;
}
