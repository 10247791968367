import {
    Actions,
    ActionsColumn,
    Column,
    CRUD,
    ExcelExportButton,
    IdColumn,
    RowRenderer
} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-nazox';
import UserEntity, {roles} from "./UserEntity";
import {useUser} from '../../providers/UserProvider';

const defaultParams = {
    sort: 'id',
    desc: true
};

export default function Users() {
    const user = useUser();

    return <Breadcrumb
        title="Users"
        data={
            [
                {
                    href: '/user',
                    name: 'Users'
                }
            ]
        }
    >
        <CRUD url="/user" noAdd={user.type !== 512} Component={UserEntity} defaultParams={defaultParams}>
            <thead>
            <tr>
                <IdColumn/>
                <Column sort="first_name">First Name</Column>
                <Column sort="last_name">Last Name</Column>
                <Column sort="email">E-Mail</Column>
                <Column sort="type">Role</Column>
                <Column sort="company.name">Company</Column>
                <ActionsColumn/>
            </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.first_name}</td>
                    <td>{row.last_name}</td>
                    <td>{row.email}</td>
                    <td>{roles[row.type]}</td>
                    <td>{row.company?.name || '-'}</td>
                    <Actions
                        edit={user.type === 512 && ("/user/" + row.id + '/edit')}
                        del={user.type === 512 && ("/user/" + row.id)}
                    />
                </tr>}
            />
            <>
                <ExcelExportButton
                    name="Users"
                    params={{
                        sort: 'id',
                        desc: false
                    }}
                    header={[
                        "ID",
                        "Name Nachname",
                        "E-Mail Adresse",
                        "Rolle",
                        "Creation Date"
                    ]}
                    map={row => [
                        row.id,
                        row.name,
                        row.email,
                        roles[row.type],
                        new Date(row.created_at)
                    ]}
                />
            </>
        </CRUD>
    </Breadcrumb>;
}
