import React from 'react';
import {FormGroup, Input, Label, ModalBody} from "reactstrap";
import {useEntity} from "react-admin-base";
import {ModalEntityEditor, SingleFilePicker} from 'react-admin-base-bootstrap';
import {useUser} from "../../providers/UserProvider";
import UserInfoInput from '../../Components/UserInfoInput';
import {Field} from '../../Components/Fields';
import {CustomTabs, Tab} from "../../Components/CustomTabs";
import {CompanySelect} from "../../Components/Selects";

export const roles = {
    '512': 'Admin',
    '256': 'Customer'
};

export default function UserEntity({url, onReload, id}) {
    const user = useUser();
    const entity = useEntity('/user', id, {type: 256});
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor title="User Management" size="xl" url={url} onReload={onReload} entity={entity}>
        <CustomTabs horizontal>
            <Tab title="Basic Information">
                <ModalBody>
                    <FormGroup>
                        <Label>Role</Label>
                        <Input
                            type="select"
                            value={data.type}
                            onChange={a => setData({type: +a.currentTarget.value, supplier: null})}
                        >
                            {Object.entries(roles).filter(([id]) => id <= user.type).map(([id, role]) => <option
                                value={id} key={id}>{role}</option>)}
                        </Input>
                    </FormGroup>
                    { data.type !== 512 && <Field title="Company" type="required">
                        <CompanySelect
                            value={data.company}
                            onChange={company => setData({ company })}
                        />
                    </Field> }
                    <UserInfoInput
                        data={data}
                        setData={setData}
                        required={false}
                    />
                </ModalBody>
            </Tab>
        </CustomTabs>
    </ModalEntityEditor>;
}
