import {Navigate, Route, Routes} from 'react-router-dom';
import Users from "./pages/User/Users";

import Profile from "./pages/Profile/Edit";

import Companies from "./pages/Company/Companies";
import Languages from "./pages/Language/Languages";
import Dashboard from './pages/Dashboard/Dashboard';
import Products from './pages/Product/Products';
import Shops from "./pages/Shop/Shops";
import {useUser} from "./providers/UserProvider";

export default function Router() {
    const user = useUser();

    return <Routes>
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/profile" element={<Profile/>}/>
        
        { user.type === 512 && <Route path="/language" element={<Languages/>}/> }
        { user.type === 512 && <Route path="/user/*" element={<Users/>}/> }
        { user.type === 512 && <Route path="/company/*" element={<Companies/>}/> }
        { user.type === 512 && <Route path="/product/*" element={<Products/>}/> }
        { user.type === 512 && <Route path="/shop/*" element={<Shops/>}/> }
        
        <Route path="*" element={<Navigate to="/dashboard" replace/>}/>
    </Routes>;
}
