import {createContext, useContext, useState} from "react";
import {useUser} from "./UserProvider";
import {CompanySelect} from "../Components/Selects";
import {Field} from "../Components/Fields";

const CompanyContext = createContext();

export default function CompanyProvider({ children }) {
    const user = useUser();
    const [ company, setCompany ] = useState();
    
    if (+user.type !== 512)
        return <CompanyContext.Provider value={user.company.id}>
            { children }
        </CompanyContext.Provider>;
    
    return <>
        <Field title="Company" type="required">
            <CompanySelect
                value={company}
                onChange={setCompany}
            />
        </Field>
        { company && <CompanyContext.Provider value={company.id}>
            { children }
        </CompanyContext.Provider> }
    </>;
}

export function useCompanyId() {
    return useContext(CompanyContext);
}
