import CompanyProvider, {useCompanyId} from "../../providers/CompanyProvider";
import {useState} from "react";
import {PeriodSelect} from "../../Components/Selects";
import {useFetch, ValidatorProvider} from "react-admin-base";
import {Field} from "../../Components/Fields";
import {Alert, Card, CardBody, CardHeader, CardText, CardTitle, Col, Row, Table} from "reactstrap";
import {Money} from "../../Components/CurrencyInput";

function Statistics({ period }) {
    const companyId = useCompanyId();
    const [ stats, loading ] = useFetch('/company/' + companyId + '/period/' + period.index + '/statistics');
    
    if (loading)
        return <Alert color="warning">
            <i className="fas fa-spinner fa-spin me-2" /> Loading data
        </Alert>;
    
    if (!stats)
        return null;
    
    return <>
        <Row>
            <Col>
                <Box title="Total Sales">
                    { stats.periodTotal.totalItemSold }
                </Box>
            </Col>
            <Col>
                <Box title="Total Cost">
                    <Money value={ stats.periodTotal.totalCost } />
                </Box>
            </Col>
        </Row>
        <Card>
            <CardHeader>
                <CardTitle className="fw-bold mb-0">
                    Sales by day
                </CardTitle>
            </CardHeader>
            <Table className="mb-0" striped hover>
                <thead>
                    <tr>
                        <th className="min text-center">Date</th>
                        <th className="min text-center">Sales</th>
                        <th>Cost</th>
                    </tr>
                </thead>
                <tbody>
                    { stats.daily.map(a => <tr key={a.date}>
                        <td className="min text-center">{a.date}</td>
                        <td className="min text-center">{a.total.totalItemSold}</td>
                        <td> <Money value={ a.total.totalCost } /></td>
                    </tr> ) }
                </tbody>
            </Table>
        </Card>
        <Card>
            <CardHeader>
                <CardTitle className="fw-bold mb-0">
                    Sales by product
                </CardTitle>
            </CardHeader>
            <Table className="mb-0" striped hover>
                <thead>
                    <tr>
                        <th>Product</th>
                        <th className="min text-center">Sales</th>
                        <th>Product Price</th>
                        <th>Cost</th>
                    </tr>
                </thead>
                <tbody>
                    { stats.products.map(a => <tr key={a.date}>
                        <td>{a.product.name}</td>
                        <td className="min text-center">{a.total.totalItemSold}</td>
                        <td> <Money value={ a.product.price } /></td>
                        <td> <Money value={ a.total.totalCost } /></td>
                    </tr> ) }
                </tbody>
            </Table>
        </Card>
    </>;
}

function Box({ title, children }) {
    return <Card className="flex-grow-1">
        <CardBody className="flex-grow-1">
            <div className="d-flex">
                <div className="flex-1 overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2"><b>{title}</b></p>
                    <h4 className="mb-0">{ children }</h4>
                </div>
                <div className="text-primary ms-auto">
                    <i className="fas fa-cash-register font-size-24"></i>
                </div>
            </div>
        </CardBody>
    </Card>
}

function DashboardInner() {
    const [ period, setPeriod ] = useState();
    
    return <>
        <Field title="Period" type="required">
            <PeriodSelect
                value={period}
                onChange={setPeriod}
            />
        </Field>
        { period && <Statistics period={period} /> }
    </>;
}


export default function Dashboard() {
    return <ValidatorProvider>
        <CompanyProvider>
            <DashboardInner />
        </CompanyProvider>
    </ValidatorProvider>;
}
