import {PasswordInput} from "react-admin-base-bootstrap";
import {Col, Input, Row} from "reactstrap";
import {Field} from "./Fields";
import PhoneInput from "react-phone-input-2";

export default function UserInfoInput({data, setData, required, pwRequired = false}) {
    return <>
        <Row>
            <Col>
                <Field title="First Name" type="required">
                    <Input
                        type="text"
                        value={data.first_name || ''}
                        onChange={a => setData({first_name: a.currentTarget.value})}
                    />
                </Field>
            </Col>
            <Col>
                <Field title="Last Name" type="required">
                    <Input
                        type="text"
                        value={data.last_name || ''}
                        onChange={a => setData({last_name: a.currentTarget.value})}
                    />
                </Field>
            </Col>
        </Row>

        <Field title="E-Mail" type="required|email">
            <Input
                type="text"
                value={data.email || ''}
                onChange={a => setData({email: a.currentTarget.value})}
            />
        </Field>

        <PasswordInput
            value={data.password || ''}
            onChange={a => setData({password: a})}
            required={pwRequired}
            placeholder={!pwRequired ? 'Type if you want to change your password' : ''}
        />

        <Field title="Phone" type={required && "required"}>
            <PhoneInput
                country="us"
                value={data.phone || ''}
                onChange={a => setData({phone: a})}
            />
        </Field>
    </>;
}