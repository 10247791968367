import React, {useState} from "react";
import {Col, Nav, NavItem, NavLink, Row} from "reactstrap";

export function CustomTabs({children, className, horizontal}) {
    const [active, setActive] = useState(0);

    if (horizontal) {
        return <>
            <Nav tabs className={"d-flex flex-row nav-tabs-custom " + (className || '')}>
                {React.Children.map(children, (a, i) => a && <NavItem key={i} className="flex-grow-1 text-center">
                    <NavLink href="#" className="py-3 px-3" active={i === active} onClick={e => {
                        e.preventDefault();
                        setActive(i);
                    }}>{a.props.title}</NavLink>
                </NavItem>)}
            </Nav>
            {
                React.Children.map(children, (a, i) => a && React.cloneElement(a, {active: active === i}))
            }
        </>
    }

    return <Row className="mb-3">
        <Col md="2">
            <Nav pills className="flex-column">
                {React.Children.map(children, (a, i) => <NavLink active={i === active}
                                                                 onClick={() => setActive(i)}>{a.props.title}</NavLink>)}
            </Nav>
        </Col>
        <Col>
            {
                React.Children.map(children, (a, i) => React.cloneElement(a, {active: active === i}))
            }
        </Col>
    </Row>;
}

type TabParams = {
    title: string;
    children?: any;
    active?: boolean;
};

export function Tab({title, children, active}: TabParams) {
    return <div style={{display: active ? 'block' : 'none'}}>
        {children}
    </div>
}
