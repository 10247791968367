import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn, RowRenderer} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-nazox';
import ProductEntity from "./ProductEntity";
import {Money} from "../../Components/CurrencyInput";

export default function Products() {
    return <Breadcrumb
        title="Products"
        data={
            [
                {
                    href: '/product',
                    name: 'Products'
                }
            ]
        }
    >
        <CRUD url="/product" Component={ProductEntity}>
            <thead>
                <tr>
                    <IdColumn/>
                    <Column sort="sku" className="min text-center">SKU</Column>
                    <Column sort="name">Name</Column>
                    <Column sort="price">Price</Column>
                    <ActionsColumn/>
                </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td className="min text-center">{row.sku}</td>
                    <td>{row.name}</td>
                    <td><Money value={row.price} /></td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/product/" + row.id}
                    />
                </tr>}
            />
        </CRUD>
    </Breadcrumb>;
}
